<backbone [currentPage]="'user-list'">
  <!-- <header-page-title-box [title]="'Lista de usuarios registrados'"
    [links]="[{title: 'Usuarios',  link: '/users'}, {title: 'Lista'}]"> -->
  <header-page-title-box>
    <div buttons>
      <p-button *showForPermissions="['usuarios_create']" size="small" icon="pi pi-user-plus" label="Crear usuario"
        [routerLink]="['/crear-usuario']"></p-button>
    </div>
  </header-page-title-box>
  <div class="p-3">
    @if (this.isLoading === true) {
    <section class="page-section bg-white rounded-2 shadow-sm p-3">
      <placeholder-table />
    </section>
    }
    @else {
    <section class="page-section bg-white rounded-2 shadow-sm">
      <table class="table table-responsive-md table-borderless rounded-2 mb-0">
        <thead class="border-bottom">
          <tr>
            <th scope="col">N. Completo</th>
            <th scope="col">N. Solicitudes (Hoy)</th>
            <th scope="col">N. Solicitudes (Periodo)</th>
            <th scope="col">R. Acceso</th>
            <th scope="col">Actividad</th>
            <th scope="col">N. Sesiones</th>
            <th scope="col">Estado</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          @if (this.users.length > 0) {
          @for (item of this.users; track $index) {
          <tr>
            <td class="align-middle fw-medium text-muted">
              {{item.firstName}} {{item.lastName}}
            </td>
            <td class="align-middle fw-medium text-muted">
              {{item.todayRequests}} de {{item.requestsAllowed}}
            </td>
            <td class="align-middle fw-medium text-muted">
              {{item.weekRequests}} en total
            </td>
            <td class="align-middle fw-medium text-muted">
              <small class="text-bg-primary p-1 px-3 rounded-2">{{findRole(item.permissionId)?.description}}</small>
            </td>
            <td class="align-middle fw-medium text-muted">
              @if (item.currentSessions > 0) {
              <small class="text-bg-success p-1 px-3 rounded-2">Conectado</small>
              }
              @else {
              <small class="text-bg-danger p-1 px-3 rounded-2">Desconectado</small>
              }
            </td>
            <td class="align-middle fw-medium text-muted">
              {{item.currentSessions}} de {{item.sessionsAllowed}}
            </td>
            <td class="align-middle fw-medium text-muted">
              @if (item.status == 'active') {
              <small class="text-bg-success p-1 px-3 rounded-2">Activo</small>
              }
              @if (item.status == 'block') {
              <small class="text-bg-danger p-1 px-3 rounded-2">Bloqueado</small>
              }
            </td>
            <td class="align-middle fw-medium text-nowrap text-end">
              <div class="d-flex justify-content-end gap-1">
                @if (item?.status == 'active') {
                <p-button *showForPermissions="['usuarios_block']" icon="pi pi-ban" severity="danger"
                  [loading]="isBlocking" (onClick)="block($event, item.username)"></p-button>
                }

                @if (item?.status == 'block') {
                <p-button *showForPermissions="['usuarios_unblock']" icon="pi pi-check-circle" severity="success"
                  [loading]="isUnblocking" (onClick)="unblock($event, item.username)"></p-button>
                }

                <p-button *showForPermissions="['usuarios_delete']" icon="pi pi-trash" severity="danger"
                  [loading]="isDeleting" (onClick)="delete($event, item.username)"></p-button>

                <p-button *showForPermissions="['use_usuarios']" icon="pi pi-cog"
                  [routerLink]="['/detalles-de-usuario', item.username]"></p-button>
              </div>
            </td>
          </tr>
          }
          }
          @else {
          <tr>
            <td scope="row" colspan="6" class="align-middle fw-medium text-center">
              <p class="m-0 p-4 opacity-50">No se encontraron resultados</p>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </section>
    }
  </div>
</backbone>