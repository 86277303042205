import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogsService } from '@services/catalogs.service';
import { UserService } from '@services/user.service';
import { ConfirmDialogService } from '@utils/confirm-dialog.service';
import { ToastService } from '@utils/toast.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'page-user-view',
  templateUrl: './user-view.component.html',
  styleUrl: './user-view.component.scss'
})

export class UserViewPage {

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private toast: ToastService,
    private catalogsService: CatalogsService,
    private confirmDialogService: ConfirmDialogService,
    private title: Title
  ) { }
  public token: string = '';
  public isLoading = false;
  public isSaving = false;
  public isResetingSessions = false;
  public isBlocking = false;
  public isUnblocking = false;
  public isDeleting = false;
  public username!: string;
  public user: any = null;
  public permissions: any = [];
  public formUser = this.formBuilder.group({
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    email: [null, [Validators.required, Validators.email]],
    phone: [null],
    sessionsAllowed: [null, Validators.required],
    requestsAllowed: [null, Validators.required],
    priceAllowed: [null, Validators.required],
    levelAccess: [null, Validators.required],
    permissionAccess: [null, Validators.required],
    password: [null, Validators.minLength(8)],
    rpassword: [null, Validators.minLength(8)],
  });

  get firstName() { return this.formUser.get('firstName') }
  set firstName(value: any) { this.formUser.get('firstName')?.setValue(value) }
  get lastName() { return this.formUser.get('lastName') }
  set lastName(value: any) { this.formUser.get('lastName')?.setValue(value) }
  get email() { return this.formUser.get('email') }
  set email(value: any) { this.formUser.get('email')?.setValue(value) }
  get phone() { return this.formUser.get('phone') }
  set phone(value: any) { this.formUser.get('phone')?.setValue(value) }
  get sessionsAllowed() { return this.formUser.get('sessionsAllowed') }
  set sessionsAllowed(value: any) { this.formUser.get('sessionsAllowed')?.setValue(value) }
  get requestsAllowed() { return this.formUser.get('requestsAllowed') }
  set requestsAllowed(value: any) { this.formUser.get('requestsAllowed')?.setValue(value) }
  get priceAllowed() { return this.formUser.get('priceAllowed') }
  set priceAllowed(value: any) { this.formUser.get('priceAllowed')?.setValue(value) }
  get levelAccess() { return this.formUser.get('levelAccess') }
  set levelAccess(value: any) { this.formUser.get('levelAccess')?.setValue(value) }
  get permissionAccess() { return this.formUser.get('permissionAccess') }
  set permissionAccess(value: any) { this.formUser.get('permissionAccess')?.setValue(value) }
  get password() { return this.formUser.get('password') }
  set password(value: any) { this.formUser.get('password')?.setValue(value) }
  get rpassword() { return this.formUser.get('rpassword') }
  set rpassword(value: any) { this.formUser.get('rpassword')?.setValue(value) }

  ngOnInit() {
    this.isLoading = true;
    this.activatedRoute.params.subscribe(async routeParams => {
      this.username = routeParams['username']
      /* this.title.setTitle('Viendo usuario: ' + this.username + ' ' + this.title.getTitle()); */
      this.catalogsService.permissions()
        .then((response: any) => this.permissions = response)
        .catch(() => this.toast.error('Error al obtener los permisos.'))
      await this.userService.getByUsername(this.username)
        .then((response: any) => {
          if (response.content.length <= 0) {
            this.toast.error('Error al obtener el usuario.')
            this.router.navigate(['/usuarios'])
          }
          this.user = response.content
        })
        .catch(() => this.toast.error('Error al obtener el usuario.'))
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
      this.email = this.user.email;
      this.phone = this.user.phone;
      this.sessionsAllowed = this.user.sessionsAllowed;
      this.requestsAllowed = this.user.requestsAllowed;
      this.priceAllowed = this.user.priceAllowed;
      this.levelAccess = this.user.levelAccess;
      this.permissionAccess = this.user.permissionAccess;
      this.isLoading = false;
    });
  }

  save = async () => {
    this.isSaving = true;
    await this.userService.save(this.username, this.formUser.value)
      .then((response) => this.toast.success('Usuario actualizado correctamente.'))
      .catch(() => this.toast.error('Error al actualizar el usuario.'))
    this.isSaving = false;
  }

  setPermissionAccess(event: Event) {
    /* TOFIX */
    let levelAccess = String(event);
    let permissionAccess: any = [];
    if (levelAccess == 'Super Administrador') {
      permissionAccess = [
        'use_rfc',
        'rfc_request',
        'rfc_all',
        'rfc_busqueda_masiva',
        'rfc_cambiar_emision',
        'use_marco',
        'use_historial',
        'historial_update',
        'historial_report',
        'use_usuarios',
        'usuarios_create',
        'usuarios_update',
        'usuarios_reset_sessions',
        'usuarios_block',
        'usuarios_unblock',
        'usuarios_delete',
        'usuarios_add_token',
      ];
    } else if (levelAccess == 'Administrador') {
      permissionAccess = [
        'use_rfc',
        'rfc_request',
        'rfc_all',
        'rfc_busqueda_masiva',
        'rfc_cambiar_emision',
        'use_marco',
        'use_historial',
        'historial_update',
        'historial_report',
        'use_usuarios',
        'usuarios_create',
        'usuarios_reset_sessions',
        'usuarios_add_token',
      ];
    } else if (levelAccess == 'Cliente') {
      permissionAccess = [
        'use_rfc',
        'rfc_request',
        'rfc_busqueda_masiva',
        'use_historial',
        'usuarios_add_token',
      ];
    } else if (levelAccess == 'Oficina') {
      permissionAccess = [
        'use_rfc',
        'rfc_request',
        'rfc_all',
        'rfc_busqueda_masiva',
        'rfc_cambiar_emision',
        'use_marco',
        'use_historial',
        'historial_update',
        'historial_report',
        'use_usuarios',
        'usuarios_create',
        'usuarios_reset_sessions',
        'usuarios_add_token',
      ];
    }
    this.formUser.get('permissionAccess')?.setValue(permissionAccess);
    /* END TOFIX */
  }

  resetSessions = (event: Event) => {
    this.isResetingSessions = true;
    this.confirmDialogService.dialog('¿Está seguro de resetear las sesiones de este usuario?', 'Continuar', 'Cancelar')
      .then(() => {
        this.userService.resetSessions(this.username)
          .then(() => {
            this.toast.success('Sesiones reseteadas correctamente.');
            this.user.currentSessions = 0;
            this.isResetingSessions = false;
          })
          .catch((error: any) => {
            this.toast.error(error.error.data.message)
            this.isResetingSessions = false
          })
      })
      .catch(() => this.isResetingSessions = false)
  }

  block = (event: Event) => {
    this.isBlocking = true;
    this.confirmDialogService.dialog('¿Está seguro de bloquear este usuario?', 'Continuar', 'Cancelar')
      .then(() => {
        this.userService.block(this.username)
          .then(() => {
            this.toast.success('Usuario bloqueado correctamente.');
            this.user.status = 'block';
            this.isBlocking = false;
          })
          .catch((error: any) => {
            this.toast.error(error.error.data.message)
            this.isBlocking = false
          })
      })
      .catch(() => this.isBlocking = false)
  }

  unblock = (event: Event) => {
    this.isUnblocking = true;
    this.confirmDialogService.dialog('¿Está seguro de desbloquear este usuario?', 'Continuar', 'Cancelar')
      .then(() => {
        this.userService.unblock(this.username)
          .then(() => {
            this.toast.success('Usuario desbloqueado correctamente.');
            this.user.status = 'active';
            this.isUnblocking = false;
          })
          .catch((error: any) => {
            this.toast.error(error.error.data.message);
            this.isUnblocking = false;
          })
      })
      .catch(() => this.isUnblocking = false)
    this.isUnblocking = false;
  }

  delete = (event: Event) => {
    this.isDeleting = true;
    this.confirmDialogService.dialog('¿Está seguro de eliminar este usuario?', 'Continuar', 'Cancelar')
      .then(() => {
        this.userService.delete(this.username)
          .then(() => {
            this.toast.success('Usuario eliminado correctamente.')
            this.router.navigate(['/usuarios'])
          })
          .catch((error: any) => {
            this.toast.error(error.error.data.message)
            this.isDeleting = false
          })
      })
      .catch(() => this.isDeleting = false)
  }

  addToken = async () => {
    await this.userService.addPDFToken({ token: this.token, user: this.username })
      .then(() => this.toast.success('Token agregado correctamente.'))
      .catch(() => this.toast.error('Error al agregar el token.'))
    this.isSaving = false;
  }
}
