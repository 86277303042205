import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReversosPage } from './reversos.component';
import { authGuard } from '@guards/auth.guard';
import { hasRoleGuard } from '@guards/has-role.guard';

const routes: Routes = [
  {
    path: 'reversos',
    component: ReversosPage,
    canActivate: [authGuard, hasRoleGuard(null, ['use_reversos'])],
    data: {
      title: "Agregar reverso"
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReversosPageRoutingModule { }
