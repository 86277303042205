<div ngbDropdown class="h-100 w-100">
  <button type="button"
    class="btn btn-{{theme}} h-100 w-100 position-relative d-flex align-items-center justify-content-between" id="user"
    ngbDropdownToggle>
    <div class="d-flex align-items-center justify-content-center">
      <span class="fs-3 me-2">
        <i class="bi bi-person-circle"></i>
      </span>
      <span class="d-flex flex-column align-items-start me-2">
        {{user}}
        <!-- <span class="d-block lh-sm" style="font-size: 11px;">{{user}}</span> -->
        <!-- <span class="d-block lh-sm" style="font-size: 10px;">sysadmin</span> -->
      </span>
    </div>
  </button>
  <div ngbDropdownMenu aria-labelledby="user">
    <!-- <button class="d-flex align-items-center" ngbDropdownItem>
      <i class="bi bi-headset fs-5 me-2"></i> Soporte
    </button> -->
    <!-- <div class="dropdown-divider"></div> -->
    <button class="d-flex align-items-center" ngbDropdownItem (click)="logout()">
      <i class="bi bi-box-arrow-right fs-5 me-2"></i> Cerrar sesión
    </button>
  </div>
</div>