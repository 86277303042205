import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RfcPageRoutingModule } from './rfc-routing.module';
import { RfcPage } from './rfc.component';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { HeaderPageTitleBoxComponent } from '@components/headers/page-title-box/page-title-box.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { PlaceholderTableComponent } from '@components/placeholders/table/table.component';
import { ShowForPermissionsDirective } from '@directives/show-for-permissions.directive';

@NgModule({
  declarations: [
    RfcPage
  ],
  imports: [
    CommonModule,
    RfcPageRoutingModule,
    BackboneComponent,
    HeaderPageTitleBoxComponent,
    ReactiveFormsModule,
    NgSelectModule,
    InputTextModule,
    ButtonModule,
    PlaceholderTableComponent,
    ShowForPermissionsDirective
  ]
})

export class RfcPageModule { }
