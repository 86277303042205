import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserViewPage } from './user-view.component';
import { authGuard } from '@guards/auth.guard';
import { hasRoleGuard } from '@guards/has-role.guard';

const routes: Routes = [
  {
    path: 'detalles-de-usuario/:username',
    component: UserViewPage,
    canActivate: [authGuard, hasRoleGuard(null, ['use_usuarios'])],
    data: {
      title: "Detalles de usuario"
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class UserViewPageRoutingModule { }
