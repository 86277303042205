export const environment = {
  app: {
    title: "Tramifast",
    apiUrl: "https://api.tramifast.com.mx",
  },
  notification: {
    apiKey: "OwHZ0SQM0fxsH3Tgan7apBM86",
    apiUrl: "https://notifications.tramifast.com.mx:3001"
  }
};
