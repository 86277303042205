import { Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';

@Injectable({
  providedIn: 'root'
})

export class CambiarEmisionService {
  
  constructor(private http: HttpService) { }

  public request = async (body: any) => await this.http.post(`/api/v1/rfc/cambiaremision`, body)
    .then((response: any) => response.data);

    public download = async (type: string, id: number) => await this.http.get(`/api/v1/changeorigin/download/${type}/${id}`)
    .then((response: any) => response.data)

    public list = async () => await this.http.get(`/api/v1/changeorigin/`)
    .then((response: any) => response.data)

}