import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '@guards/auth.guard';
import { MarcoPage } from './marco.component';
import { hasRoleGuard } from '@guards/has-role.guard';

const routes: Routes = [
  {
    path: 'marcos',
    component: MarcoPage,
    canActivate: [authGuard, hasRoleGuard(null, ['use_marco'])],
    data: {
      title: "Marcos"
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class MarcoPageRoutingModule { }
