import { Component } from '@angular/core';
import { BusquedaMasivaService } from '@services/busqueda-masiva.service';
import { RfcService } from '@services/rfc.service';
import { ToastService } from '@utils/toast.service';
import { environment } from '@environments/environment';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'page-busqueda-masiva',
  templateUrl: './busqueda-masiva.component.html',
  styleUrl: './busqueda-masiva.component.scss'
})

export class BusquedaMasivaPage {
  constructor(
    private formBuilder: FormBuilder,
    private busquedaMasivaService: BusquedaMasivaService,
    private rfcService: RfcService,
    private toast: ToastService
  ) { }

  public apiUrl = environment.app.apiUrl;

  public isLoading = true;
  public isUpload = false;

  public form = this.formBuilder.group({
    fileTmp: [null, [Validators.required]],
    file: [null, [Validators.required]],
  });

  get fileTmp() { return this.form.get('fileTmp') }
  set fileTmp(value: any) { this.fileTmp?.setValue(value) }
  get file() { return this.form.get('file') }
  set file(value: any) { this.file?.setValue(value) }

  public list: any = [];

  async ngOnInit() {
    await this.init()
  }

  init = async () => {
    this.isLoading = true;
    await this.getList()
    this.isLoading = false;
  }

  onFileChanged = async (event: any) => {
    if (event.target.files && event.target.files.length) {
      this.file = event.target.files[0];
      /*this.fileTmp = event.target.files[0];*/
      await this.request();
    }
  }

  getList = async () => {
    await this.busquedaMasivaService.getList()
      .then((response: any) => this.list = response.content)
      .catch(() => this.toast.error('Error al obtener las búsquedas masivas.'));
  }

  request = async () => {
    this.isUpload = true;

    const myFormData = new FormData();

    myFormData.append('file', this.file.value)

    await this.rfcService.massiveUpload(myFormData)
      .then((response: any) => {
        this.toast.success('Búsqueda agregada correctamente.');
        this.isLoading = false;
        this.isUpload = false;
        this.form.reset();
        this.init();
      })
      .catch((error: any) => this.toast.error(error.error.data.message))

    setTimeout(() => this.isUpload = false, 500);
  }

  // onFileUploadClicked(event: { files: Blob[] }, form: any) {
  //   this.isLoading = true;
  //   this.isUpload = true;
  //   let body = new FormData();
  //   body.append('file', event.files[0]);
  //   this.rfcService.massiveUpload(body)
  //     .then((response: any) => {
  //       this.toast.success('Búsqueda agregada correctamente.');
  //       this.isLoading = false;
  //       this.isUpload = false;
  //       form.clear();
  //       this.init();
  //     })
  //     .catch((error: any) => this.toast.error('Error al agregar la búsqueda.'));
  // }
}
