import { Component } from '@angular/core';
import { HistorialService } from '@services/historial.service';
import { ToastService } from '@utils/toast.service';
import { environment } from '@environments/environment';
import * as XLSX from 'xlsx';
import { UserService } from '@services/user.service';
import { FormBuilder, Validators } from '@angular/forms';
import { RfcService } from '@services/rfc.service';

@Component({
  selector: 'page-historial',
  templateUrl: './historial.component.html',
  styleUrl: './historial.component.scss'
})

export class HistorialPage {

  constructor(
    private historialService: HistorialService,
    private toast: ToastService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private rfcService: RfcService
  ) { }

  public apiUrl = environment.app.apiUrl;
  public list: any = [];
  public isLoading = true;
  public periodSelected = 'current';
  public userList: any = [];
  public visibleDialogUpdateRequest: boolean = false;
  public isSavingUpdateRequest: boolean = false;
  public periodoActual: Date = new Date();
  public periodo1: Date = new Date();
  public periodo2: Date = new Date();
  public periodo3: Date = new Date();
  public periodo4: Date = new Date();
  public periodo5: Date = new Date();
  public periodo6: Date = new Date();
  public periodo7: Date = new Date();
  public periodo8: Date = new Date();

  async ngOnInit() {
    await this.getListUsers()
    await this.init()
  }

  init = async () => {
    this.isLoading = true;
    this.getPeriodo();
    await this.getListHistorial()
    this.isLoading = false;
  }

  getListUsers = async () => {
    await this.userService.getListAll()
      .then((response: any) => this.userList = response)
      .catch(() => this.toast.error('Error al obtener los usuarios.'))
  }

  getPeriodo()
  {
    let fechaActual = new Date();
    let fDiaActual = fechaActual.getDate();
    let nDiaActual = fechaActual.getDay() + 1;

    let restaPeriodoActual = 0;
    let restaPeriodo1 = 0;
    let restaPeriodo2 = 0;
    let restaPeriodo3 = 0;
    let restaPeriodo4 = 0;
    let restaPeriodo5 = 0;
    let restaPeriodo6 = 0;
    let restaPeriodo7 = 0;
    let restaPeriodo8 = 0;

    let fechaPeriodoActual = new Date();
    let fechaPeriodo1 = new Date();
    let fechaPeriodo2 = new Date();
    let fechaPeriodo3 = new Date();
    let fechaPeriodo4 = new Date();
    let fechaPeriodo5 = new Date();
    let fechaPeriodo6 = new Date();
    let fechaPeriodo7 = new Date();
    let fechaPeriodo8 = new Date();

    if (nDiaActual == 7)
    {
      restaPeriodo1 = 7 * 1;
      restaPeriodo2 = 7 * 2;
      restaPeriodo3 = 7 * 3;
      restaPeriodo4 = 7 * 4;
      restaPeriodo5 = 7 * 5;
      restaPeriodo6 = 7 * 6;
      restaPeriodo7 = 7 * 7;
      restaPeriodo8 = 7 * 8;
    }
    else if (nDiaActual < 7)
    {
      restaPeriodoActual = nDiaActual;
      restaPeriodo1 = nDiaActual + (7 * 1);
      restaPeriodo2 = nDiaActual + (7 * 2);
      restaPeriodo3 = nDiaActual + (7 * 3);
      restaPeriodo4 = nDiaActual + (7 * 4);
      restaPeriodo5 = nDiaActual + (7 * 5);
      restaPeriodo6 = nDiaActual + (7 * 6);
      restaPeriodo7 = nDiaActual + (7 * 7);
      restaPeriodo8 = nDiaActual + (7 * 8);
    }

    this.periodoActual = new Date(fechaPeriodoActual.setDate(fDiaActual - restaPeriodoActual));
    this.periodo1 = new Date(fechaPeriodo1.setDate(fDiaActual - restaPeriodo1));
    this.periodo2 = new Date(fechaPeriodo2.setDate(fDiaActual - restaPeriodo2));
    this.periodo3 = new Date(fechaPeriodo3.setDate(fDiaActual - restaPeriodo3));
    this.periodo4 = new Date(fechaPeriodo4.setDate(fDiaActual - restaPeriodo4));
    this.periodo5 = new Date(fechaPeriodo5.setDate(fDiaActual - restaPeriodo5));
    this.periodo6 = new Date(fechaPeriodo6.setDate(fDiaActual - restaPeriodo6));
    this.periodo7 = new Date(fechaPeriodo7.setDate(fDiaActual - restaPeriodo7));
    this.periodo8 = new Date(fechaPeriodo8.setDate(fDiaActual - restaPeriodo8));
  }

  getListHistorial = async () => {
    await this.historialService.list(this.periodSelected)
      .then((response: any) => this.list = response.content)
      .catch(() => this.toast.error('Error al obtener el historial.'));
  }

  exportToExcel(target: any): void {
    let element = document.getElementById(target);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Historial');
    XLSX.writeFile(wb, 'Reporte de historial.xlsx');
  }

  public formUpdateRequest = this.formBuilder.group({
    rfcRequestId: [null, Validators.required],
    userId: [null, Validators.required],
    price: [null, Validators.required]
  });

  get rfcRequestId() { return this.formUpdateRequest.get('rfcRequestId') }
  set rfcRequestId(value: any) { this.formUpdateRequest.get('rfcRequestId')?.setValue(value) }
  get userId() { return this.formUpdateRequest.get('userId') }
  set userId(value: any) { this.formUpdateRequest.get('userId')?.setValue(value) }
  get price() { return this.formUpdateRequest.get('price') }
  set price(value: any) { this.formUpdateRequest.get('price')?.setValue(value) }

  updateRequest = (item: any) => {
    this.visibleDialogUpdateRequest = true;
    this.rfcRequestId = item.rfcRequestId
    this.userId = item.userId
    this.price = Number(item.price)
  }

  saveUpdateRequest = () => {
    this.isSavingUpdateRequest = true;
    this.rfcService.updateRequest(this.formUpdateRequest.value)
      .then((response: any) => {
        this.toast.success('Historial actualizado correctamente.')
        this.visibleDialogUpdateRequest = false;
        this.isSavingUpdateRequest = false;
        this.init()
        this.formUpdateRequest.reset()
      })
      .catch(() => this.toast.error('Error al actualizar el historial.'))
  }
}
