import { inject, Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';

@Injectable({
  providedIn: 'root'
})
export class MarcosService {
  private http = inject(HttpService)

  public upload = async (body: any) => await this.http.post(`/v1/marcos`, body)
    .then((response: any) => response.data)

  public list = async () => await this.http.get(`/v1/marcos`, false)
    .then((response: any) => response.data.content.sort())
}
