import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { SessionService } from '@utils/session.service';

@Directive({
  selector: '[showForPermissions]',
  standalone: true
})
export class ShowForPermissionsDirective {
  @Input('showForPermissions') allowedPermissions?: string[];

  constructor(
    private session: SessionService,
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) { }

  ngOnInit() {
    return (this.allowedPermissions && this.session.existsPermissions(this.allowedPermissions)) ?
      this.viewContainerRef.createEmbeddedView(this.templateRef) : this.viewContainerRef.clear();
  }
}
