import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserListPageRoutingModule } from './user-list-routing.module';
import { UserListPage } from './user-list.component';
import { HeaderPageTitleBoxComponent } from '@components/headers/page-title-box/page-title-box.component';
import { PlaceholderTableComponent } from '@components/placeholders/table/table.component';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { ShowForPermissionsDirective } from '@directives/show-for-permissions.directive';

@NgModule({
  declarations: [
    UserListPage
  ],
  imports: [
    CommonModule,
    UserListPageRoutingModule,
    RouterModule,
    BackboneComponent,
    PlaceholderTableComponent,
    HeaderPageTitleBoxComponent,
    ButtonModule,
    ShowForPermissionsDirective
  ]
})

export class UserListPageModule { }
