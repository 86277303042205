<header id="topbar" class="text-bg-light border-bottom">
  <div class="container-fluid d-flex justify-content-between align-items-center h-100">
    <div class="topbar__left">
      <label class="hamburguer" for="check">
        <input type="checkbox" id="check" (change)="toggleMainMenu()" />
        <span></span>
        <span></span>
        <span></span>
      </label>
    </div>
    <div class="topbar__right h-100">
      <ul class="menu">
        <!-- <li class="menu-item ms-1">
          <search [theme]="'light'" [currentPage]="this.currentPage">
            <i class="bi bi-search fs-5"></i>
          </search>
        </li> -->
        <!-- <li class="menu-item ms-1">
          <bell-button />
        </li>
        <li class="menu-item ms-1">
          <dropdown-language [theme]="'light'" style="height: 40px;" />
        </li> -->
        <li class="menu-item ms-1">
          <dropdown-user-account [theme]="'light'" style="height: 40px;" />
        </li>
      </ul>
    </div>
  </div>
</header>