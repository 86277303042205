import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserListPage } from './user-list.component';
import { authGuard } from '@guards/auth.guard';
import { hasRoleGuard } from '@guards/has-role.guard';

const routes: Routes = [
  {
    path: 'usuarios',
    component: UserListPage,
    canActivate: [authGuard, hasRoleGuard(null, ['use_usuarios'])],
    data: {
      title: "Usuarios"
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class UserListPageRoutingModule { }
