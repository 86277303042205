import { Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';
import { UtilsService } from '@utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private http: HttpService,
    private utils: UtilsService
  ) { }

  public getList = async () => await this.http.get(`/api/v1/users`)
    .then((response: any) => this.utils.orderArrayBy(response.data.content, 'firstName'))

  public getListAll = async () => await this.http.get(`/api/v1/users/catalog/all`)
    .then((response: any) => this.utils.orderArrayBy(response.data.content, 'firstName'))

  public getByUsername = async (username: string) => await this.http.get(`/api/v1/users/${username}?by=username`)
    .then((response: any) => response.data)

  public create = async (body: any) => await this.http.post(`/api/v1/users`, body)
    .then((response: any) => response.data)

  public save = async (username: string, body: any) => await this.http.put(`/api/v1/users/${username}?by=username`, body)
    .then((response: any) => response.data)

  public resetSessions = async (username: string) => await this.http.delete(`/api/v1/users/${username}?by=username&type=resetSessions`)
    .then((response: any) => response.data) /* TOFIX: No utilizar http delete */

  public block = async (username: string) => await this.http.delete(`/api/v1/users/${username}?by=username&type=block`)
    .then((response: any) => response.data) /* TOFIX: No utilizar http delete */

  public unblock = async (username: string) => await this.http.delete(`/api/v1/users/${username}?by=username&type=unblock`)
    .then((response: any) => response.data) /* TOFIX: No utilizar http delete */

  public delete = async (username: string) => await this.http.delete(`/api/v1/users/${username}?by=username&type=delete`)
    .then((response: any) => response.data) /* TOFIX: No enviar parametro type */

  public addPDFToken = async (body: any) => await this.http.post(`/api/v1/users/addtoken`, body)
    .then((response: any) => response.data)
}
