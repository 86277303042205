<backbone currentPage="Marco">
  <div class="p-3">

    @if (this.isLoading === true) {
    <section class="page-section bg-white rounded-2 shadow-sm p-3">
      <placeholder-table />
    </section>
    }
    @else {
    <div [formGroup]="formUpload" class="page-section card">
      <div class="card-header d-flex justify-content-between align-items-center">
        <label>Subida de actas de nacimiento.</label>
        <p-button label="Subir documentos" [loading]="isUploading" (onClick)="upload()" size="small"
          [disabled]="this.files.controls.length === 0" />
      </div>
      <div class="card-body d-flex flex-column gap-3">
        <div class="d-flex align-items-center gap-4">
          <div class="d-flex align-items-center gap-2">
            <p-inputSwitch inputId="marcosChecked" formControlName="marcosChecked" class="d-flex" />
            <label for="marcosChecked" role="button">Agregar marcos</label>
          </div>
          <div class="d-flex align-items-center gap-2">
            <p-inputSwitch inputId="foliosChecked" formControlName="foliosChecked" class="d-flex" />
            <label for="foliosChecked" role="button">Agregar folio</label>
          </div>
          <div class="d-flex align-items-center gap-2">
            <p-inputSwitch inputId="reversosChecked" formControlName="reversosChecked" class="d-flex" />
            <label for="reversosChecked" role="button">Agregar reverso</label>
          </div>
        </div>

        <form id="upload">
          <p
            class="m-auto position-absolute start-0 end-0 bottom-0 top-0 d-flex flex-column justify-content-center align-items-center fs-4 fw-normal opacity-50">
            Da click o arrastra los archivos</p>
          <input (change)="onInputFileChanged($event)" type="file" class="form-control" accept=".pdf" multiple>
        </form>

        <div class="d-flex flex-wrap gap-3">
          @for (item of files.controls; track item; let index = $index) {
          <div formArrayName="files">
            <!-- <div class="card h-100 border-0" [ngClass]="getFilesAsIndex(index)?.errors !== null ? 'text-bg-danger text-white' : 'text-bg-light'"> -->
            <div class="card h-100 border-0"
              [ngClass]="{'text-bg-light': getFilesAsIndex(index)?.errors === null, 'text-bg-danger text-white': getFilesAsIndex(index)?.errors !== null}">
              <div class="card-body d-flex align-items-center gap-3">
                <p class="m-0">{{item.value.file.name}}
                  @if (getFilesAsIndex(index)?.hasError('requiredFileSize')) {
                  <small class="d-block">El tamaño del archivo no debe ser mayor a 2mb.</small>
                  }
                  @if (getFilesAsIndex(index)?.hasError('requiredFileType')) {
                  <small class="d-block">No es un archivo válido.</small>
                  }
                </p>

                @if (getFilesAsIndex(index)?.errors !== null) {
                <p-button icon="pi pi-trash" severity="danger" size="small" styleClass="rounded-2"
                  (click)="removeFile(index)"></p-button>
                }
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    </div>

    <div class="page-section card">
      <div class="card-body p-0">
        <p-table #dt1 [value]="this.list" dataKey="id" [rows]="25" [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[25, 50, 100]" [paginator]="true"
          [globalFilterFields]="['curp', 'identificadorElectronico']">
          <ng-template pTemplate="caption">
            <div class="d-flex align-items-stretch justify-content-between gap-2">
              <span class="p-input-icon-left ml-auto w-50">
                <i class="pi pi-search"></i>
                <input pInputText type="text" [(ngModel)]="searchValue" class="w-100 h-50px"
                  (input)="dt1.filterGlobal(searchValue, 'contains')"
                  placeholder="Buscar por CURP o identificador electrónico." />
              </span>

              <p-calendar [(ngModel)]="rangeDates" selectionMode="range" [readonlyInput]="true" [numberOfMonths]="1"
                [inputStyle]="{width: '200px', height: '50px'}" (ngModelChange)="test($event)" placeholder="" />
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th>TIPO</th>
              <th>CURP</th>
              <th>IDENTIFICADOR ELECTRONICO</th>
              <th colspan="2">FECHA</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td class="text-nowrap">{{item.type}}</td>
              <td class="text-nowrap">{{item.curp}}</td>
              <td class="text-nowrap">{{item.identificadorElectronico}}</td>
              <td class="text-nowrap">{{item.dateCreated | date: 'longDate'}}</td>
              <td class="text-nowrap text-end">
                @if (item.download === 0) {
                <a href="{{apiUrl}}/v1/marcos/download/{{item.identificadorElectronico}}" download target="_blank"
                  rel="noopener noreferrer" class="p-button text-decoration-none p-button-success"
                  (click)="item.download = 1">
                  <i class="pi pi-download"></i>
                </a>
                }
                @else {
                <a href="{{apiUrl}}/v1/marcos/download/{{item.identificadorElectronico}}" download target="_blank"
                  rel="noopener noreferrer" class="p-button text-decoration-none">
                  <i class="pi pi-download"></i>
                </a>
                }
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    }
  </div>
</backbone>
