import { Component, inject } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { environment } from '@environments/environment';
import { MarcosService } from '@services/marcos.service';
import { requiredFileSize, requiredFileType } from '@utils/validators';

@Component({
  selector: 'page-marco',
  templateUrl: './marco.component.html',
  styleUrl: './marco.component.scss'
})

export class MarcoPage {
  private formBuilder = inject(FormBuilder);
  private marcosService = inject(MarcosService);

  public apiUrl = environment.app.apiUrl;
  public maxSizesUpload = 2000000;
  public isLoading = false;
  public isUploading = false;

  public list: any = [];
  public rangeDates: Date[] | undefined;
  public searchValue: string | undefined;

  public formUpload = this.formBuilder.group({
    files: this.formBuilder.array([]),
    marcosChecked: [true, Validators.required],
    foliosChecked: [false, Validators.required],
    reversosChecked: [false, Validators.required],
    test: [null, Validators.required]
  });

  get marcosChecked() { return this.formUpload.get('marcosChecked') }
  set marcosChecked(value: any) { this.marcosChecked.setValue(value) }
  get foliosChecked() { return this.formUpload.get('foliosChecked') }
  set foliosChecked(value: any) { this.foliosChecked.setValue(value) }
  get reversosChecked() { return this.formUpload.get('reversosChecked') }
  set reversosChecked(value: any) { this.reversosChecked.setValue(value) }
  get files(): FormArray { return this.formUpload.controls["files"] as FormArray; }
  getFilesAsIndex = (index: number, get = 'file') => this.files.controls[index].get(get);

  async ngOnInit() {
    await this.refresh();
  }

  refresh = async () => {
    await this.marcosService.list().then((response) => this.list = response)
  }

  onInputFileChanged = (event: any) => {
    if (event.target.files && event.target.files.length) {
      for (const element of event.target.files) {
        let reader = new FileReader();

        reader.onload = (e: any) => {
          this.files.push(this.formBuilder.group({
            file: [
              element, [
                requiredFileType(['pdf']),
                requiredFileSize(this.maxSizesUpload)
              ]
            ],
            url: e.target.result, // Base64 string for preview image,
            upload: false
          }))
        }

        reader.readAsDataURL(element);
      }
    }

    event.target.value = null;
  }

  removeFile = (index: number) => this.files.removeAt(index);

  upload = async () => {
    this.isUploading = true;

    const myFormData = new FormData();

    myFormData.append('marcosChecked', this.marcosChecked.value)
    myFormData.append('foliosChecked', this.foliosChecked.value)
    myFormData.append('reversosChecked', this.reversosChecked.value)

    for (const [index, item] of this.files.value.entries()) {
      if (this.getFilesAsIndex(index)?.errors === null) {
        myFormData.append(item.file.name, item.file)
      }
    }

    this.marcosService.upload(myFormData).then(() => {
      this.isUploading = false
      this.files.clear()
      this.marcosChecked = true
      this.foliosChecked = false
      this.reversosChecked = false

      this.refresh();
    });
  }

  test(event: any) {
    console.log(event)
  }
}
