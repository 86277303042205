import { Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';

@Injectable({
  providedIn: 'root'
})
export class ReversosService {
  constructor(
    private http: HttpService
  ) { }

  public create = async (body: any) => await this.http.post(`/api/v1/reverso`, body)
    .then((response: any) => response.data)

  public list = async () => await this.http.get(`/api/v1/reverso`)
    .then((response: any) => response.data)
}
