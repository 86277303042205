import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CambiarEmisionPageRoutingModule } from './cambiar-emision-routing.module';
import { CambiarEmisionPage } from './cambiar-emision.component';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { HeaderPageTitleBoxComponent } from '@components/headers/page-title-box/page-title-box.component';
import { ShowForPermissionsDirective } from '@directives/show-for-permissions.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { NgSelectModule } from '@ng-select/ng-select';
import { PlaceholderTableComponent } from '@components/placeholders/table/table.component';

@NgModule({
  declarations: [
    CambiarEmisionPage
  ],
  imports: [
    CommonModule,
    CambiarEmisionPageRoutingModule,
    BackboneComponent,
    HeaderPageTitleBoxComponent,
    ShowForPermissionsDirective,
    ReactiveFormsModule,
    ButtonModule,
    NgSelectModule,
    PlaceholderTableComponent
  ]
})

export class CambiarEmisionPageModule { }
